import {
    COMMON_NOTIFICATION, GET_USER_ACTIVITY_HISTORY,
    GET_CRM_SUPPORT_AGENT,
    GET_USERNAME_BY_NUMBER,
    GET_SIM_DETAILS,
    CHANGE_PASSWORD
  } from '../constants';
  
  const initialState = { 
    common_notification:{
      message: '',
      isSuccess: 1
  },
  userActivityData: {},
  crmSupportAgentList: [],
  getUserNameDetails: {},
  getSimPinDetails: {},
  }
  
  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
  
      case COMMON_NOTIFICATION:
        return { ...state, 
          common_notification: (payload.message) ? payload : initialState.common_notification
      }

      case GET_USER_ACTIVITY_HISTORY: {
        return {
          ...state,
          userActivityData: payload,
        };
      }

      case CHANGE_PASSWORD: {
        return {
          ...state,
          changePassword: payload,
        };
      }

      case GET_CRM_SUPPORT_AGENT: {
        return {
          ...state,
          crmSupportAgentList: payload,
        };
      }

      case GET_USERNAME_BY_NUMBER: {
        return {
          ...state,
          getUserNameDetails: payload,
        };
      }

      case GET_SIM_DETAILS: {
        return {
          ...state,
          getSimPinDetails: payload,
        };
      }
    
      default:
        return state;
    }
  }
  
  export default reducer;