import {
  GET_VOUCHER_STATUS,
  TRANSFER_BALANCE,
  VOUCHER_STATUS_TYPE
} from 'redux/constants';

const initialState = {
  transferBalanceData: {},
  voucherStatusDetails : {},
  voucherStatusTypeData: {},
};
const reducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {

    case TRANSFER_BALANCE: {
      return {
        ...state,
        transferBalanceData: payload,
      };
    }

    case GET_VOUCHER_STATUS: {
      return {
        ...state,
        voucherStatusDetails: payload,
      };
    }

    case VOUCHER_STATUS_TYPE: {
      return {
        ...state,
        voucherStatusTypeData: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
