import {
  GET_TICKET_COUNT,
  GET_OPEN_TICKET,
  GET_ANSWER_TICKET,
  MANAGE_SUPPORT_RESPONSE,
  CHANGE_TICKET_STATUS,
  GET_USER_TICKET,
  GET_CLOSE_TICKET,
  GET_TICKET_TYPES,
  MANAGE_SUPPORT_QUESTION,
  GET_ALL_TICKET,
  GET_TICKET_DETAILS,
  TICKET_NOTIFICATION,
} from "redux/constants";

const initialState = {
  allTicketTypes: [],
  supportQuestionDetails: {},
  ticketCountDetails: {},
  openTicketsData: [],
  closeTicketsData: [],
  userTicketsData: [],
  answeredTicketsData: [],
  supportResponse: {},
  ticketStatusDetails: {},
  allTickets: {},
  ticketDetailsData: {},
  ticket_notification:{
    message: '',
    isSuccess: 1
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_TICKET_COUNT: {
      return {
        ...state,
        ticketCountDetails: payload,
      };
    }

    case TICKET_NOTIFICATION:
      return { ...state, 
        ticket_notification: (payload.message) ? payload : initialState.ticket_notification
      }

    case GET_TICKET_TYPES: {
      return {
        ...state,
        allTicketTypes: payload,
      };
    }

    case MANAGE_SUPPORT_QUESTION: {
      return { ...state, supportQuestionDetails: payload }
    }

    case GET_ALL_TICKET: {
      return {
        ...state,
        allTickets: payload,
      };
    }

    case GET_TICKET_DETAILS: {
      return {
        ...state,
        ticketDetailsData: payload,
      };
    }

    case GET_OPEN_TICKET: {
      return {
        ...state,
        openTicketsData: payload,
      };
    }

    case GET_CLOSE_TICKET: {
      return {
        ...state,
        closeTicketsData: payload,
      };
    }

    case GET_USER_TICKET: {
      return {
        ...state,
        userTicketsData: payload,
      };
    }

    case GET_ANSWER_TICKET: {
      return {
        ...state,
        answeredTicketsData: payload,
      };
    }

    case MANAGE_SUPPORT_RESPONSE:
      return { ...state, answeredTicketsData: [payload, ...state.answeredTicketsData] }


    case CHANGE_TICKET_STATUS:
      return {
        ...state,
        ticketStatusDetails: payload,
      };

    default:
      return state;
  }
};
export default reducer;
