import { GET_NOTIFICATION_COUNT, GET_NOTIFICATION_LIST, GENERATE_TICKET, CHANGE_USER_NOTIFICATION } from "redux/constants";

const initialState = {
  userNotificationCount: {},
  userNotificationList: {},
  generateTicketDetails: {},
  changeUserNotification: {},
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_NOTIFICATION_COUNT: {
      return {
        ...state,
        userNotificationCount: payload,
      };
    }
    case GET_NOTIFICATION_LIST:
      return {
        ...state,
        userNotificationList: payload,
      };

    case GENERATE_TICKET:
      return {
        ...state,
        generateTicketDetails: payload,
      };

    case CHANGE_USER_NOTIFICATION:
      return {
        ...state,
        changeUserNotification: payload,
      };

    default:
      return state;
  }
};
export default reducer;
