import { 
  CHANGE_OPTION, 
  COUNTER_QUERY, 
  CHANGE_STATUS,
  GET_CALL_RECORD,
  GET_PADS_RECORD, 
  GET_SUBSCRIPTION_RECORD, 
  GET_ACCOUNT_COUNT_RECORD, 
  GET_ADJUSTMENT_TYPE_RECORD, 
  GET_SMS_RECORD,
  CRM_VOUCHER_TOPUP_REPORT,
  GET_CDR_TYPE, } from "redux/constants";

const initialState = {
  counterQueryData: {},
  changeOptionDetails: {},
  changeStatusData: {},
  callRecordData : {},
  padsRecordData : {},
  subscriptionHistoryRecordData : {},
  accountCountRecordData : {},
  adjustmentTypeRecordData : {},
  smsRecordData : {},
  cdrTypes : [],
  crmVoucherReportDetails: {},
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case COUNTER_QUERY: {
      return {
        ...state,
        counterQueryData: payload,
      };
    }

    case CHANGE_OPTION:
      return {
        ...state,
        changeOptionDetails: payload,
      };

    case CHANGE_STATUS: {
      return {
        ...state,
        changeStatusData: payload,
      };
    }

    case GET_CALL_RECORD: {
      return {
        ...state,
        callRecordData: payload,
      };
    }

    case GET_PADS_RECORD: {
      return {
        ...state,
        padsRecordData: payload,
      };
    }

    case GET_SUBSCRIPTION_RECORD: {
      return {
        ...state,
        subscriptionHistoryRecordData: payload,
      };
    }

    case GET_ACCOUNT_COUNT_RECORD: {
      return {
        ...state,
        accountCountRecordData: payload,
      };
    }

    case GET_ADJUSTMENT_TYPE_RECORD: {
      return {
        ...state,
        adjustmentTypeRecordData: payload,
      };
    }

    case GET_SMS_RECORD: {
      return {
        ...state,
        smsRecordData: payload,
      };
    }

    case GET_CDR_TYPE: {
      return {
        ...state,
        cdrTypes: payload,
      };
    }

    case CRM_VOUCHER_TOPUP_REPORT: {
      return {
        ...state,
        crmVoucherReportDetails: payload,
      };
    }

    default:
      return state;
  }
};
export default reducer;
