import { combineReducers } from "redux";
import settings from "./settings/reducer";
import user from "./auth/reducer";
import common from "./common/reducer";
import support from "./support/reducer";
import voucher from "./voucher/reducer";
import customer from "./customer/reducer";
import ticket from "./tickets/reducer";
import simServices from "./SimServices/reducer";
import portout from "./portout/reducer";
import portIn from "./portIn/reducer";
import notification from "./notification/reducer";
import enghouseReducer from "./enghouse/reducer";
import endUser from "./EndCustomer/reducer";

const reducers = combineReducers({
  settings,
  user,
  common,
  support,
  voucher,
  customer,
  ticket,
  simServices,
  portout,
  portIn,
  notification,
  enghouseReducer,
  endUser,
});

export default reducers;
