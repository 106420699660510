import { MANAGE_MNP_PORTOUT, ADJUST_BALANCE, SIM_SWAP, MSISDN_SWAP, FUND_TRANSFER_REQUEST, GET_FUND_TRANSFER_LIST, GET_CUSTOMER_DETAILS_BY_MOBNUM } from "redux/constants";

const initialState = {
  mnpPortOutData: {},
  adjustBalanceData: {},
  simSwapData: {},
  msisdnSwapData: {},
  customerData: {},
  fundTransferResponse: {},
  fundTransferListData: {},
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MANAGE_MNP_PORTOUT:
      return {
        ...state,
        mnpPortOutData: payload,
      };

      case ADJUST_BALANCE: {
        return {
          ...state,
          adjustBalanceData: payload,
        };
      };
      case SIM_SWAP: {
        return {
          ...state,
          simSwapData: payload,
        };
      };

      case MSISDN_SWAP: {
        return {
          ...state,
          msisdnSwapData: payload,
        };
      };

      case GET_CUSTOMER_DETAILS_BY_MOBNUM:
        return {
          ...state,
          customerData: payload,
        };
  
      case FUND_TRANSFER_REQUEST:
        return {
          ...state,
          fundTransferResponse: payload,
        };
  
      case GET_FUND_TRANSFER_LIST:
        return {
          ...state,
          fundTransferListData: payload,
        };

    default:
      return state;
  }
};
export default reducer;