import {
  GET_LIST_PARTITIONS,
  SET_OPTIN,
  REPLY_TO_PARTITIONS
} from "redux/constants";

const initialState = {
  partitionListData: [],
  optinDetails: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LIST_PARTITIONS: {
      return {
        ...state,
        partitionListData: payload,
      };
    }

    case SET_OPTIN: {
      return {
        ...state,
        optinDetails: payload,
      };
    }

    case REPLY_TO_PARTITIONS:
      return { ...state, partitionListData: [payload, ...state.partitionListData] }

    default:
      return state;
  }
};
export default reducer;
