import { GET_ENG_MOBILENUMBER, GET_ENG_CUSTOMER, GET_ENG_OPTIN, GET_ENG_PORTIN, GET_PORTOUT_RECORDS, PORTOUT_COMMONE } from "redux/constants";

const initialState = {
  engMobileNumberData: [],
  engCustomerData: [],
  engOptinData: [],
  engPortinData: [],
  portoutRecordsData: [],
  portOutCommoneDetails: null,
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ENG_MOBILENUMBER: {
      return {
        ...state,
        engMobileNumberData: payload,
      };
    }
    case GET_ENG_CUSTOMER:
      return {
        ...state,
        engCustomerData: payload,
      };

    case GET_ENG_OPTIN:
      return {
        ...state,
        engOptinData: payload,
      };

    case GET_ENG_PORTIN:
      return {
        ...state,
        engPortinData: payload,
      };

    case GET_PORTOUT_RECORDS:
      return {
        ...state,
        portoutRecordsData: payload,
      };

    case PORTOUT_COMMONE: {
      return {
        ...state,
        portOutCommoneDetails: payload,
      };
    }

    default:
      return state;
  }
};
export default reducer;
