import { GET_END_USER, GET_UNIQUE_END_USER, UPDATE_END_USER } from 'redux/constants';

const initialState = {
  endUserReport: [],
  uniqueEndUserReport: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_END_USER: {
      return {
        ...state,
        endUserReport: payload,
      };
    }
    case GET_UNIQUE_END_USER: {
      return {
        ...state,
        uniqueEndUserReport: payload,
      };
    }
    case UPDATE_END_USER: {
      return {
        ...state, 
        endUserReport:
         state.endUserReport.map(item => item.customer_id === payload.customer_id ? 
          { ...item, ...payload} : item 
        )
      }
    }
    default:
      return state;
  }
};
export default reducer;