import {
  GET_CUSTOMER_INFO,
  PORTIN_PRIVATE,
  PORTIN_PRIVATE_ASAP,
  PORTIN_PRIVATE_VTEN,
  PORTIN_COMPANY,
  PORTIN_COMPANY_ASAP,
  PORTIN_COMPANY_VTEN,
  PORTIN_COMMONE,
  GET_LISTPORTATION_PORTIN,
  MANAGE_MNP_PORTIN,
  GET_PORTIN_LIST,
  INITIATE_PORTIN,
  GET_PORTIN_ICCID,
  GET_PROVIDERS_LIST,
} from "redux/constants";

const initialState = {
  customerInfo: [],
  portInPrivateData: {},
  portInPrivateAsapData: {},
  portInPrivateVtenData: {},
  portInCompanyData: {},
  portInCompanyAsapData: {},
  portInCompanyVtenData: {},
  portationListData: [],
  portInCommoneDetails: null,
  managePortInData: {},
  portInListData: [],
  portinInitiateData: {},
  portinIccidData: {},
  mnpProvidersList: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MANAGE_MNP_PORTIN: {
      return {
        ...state,
        managePortInData: payload,
      };
    }

    case GET_PORTIN_LIST:
      return {
        ...state,
        portInListData: payload,
      };

    case INITIATE_PORTIN:
      return {
        ...state,
        portinInitiateData: payload,
      };

    case GET_PORTIN_ICCID:
      return {
        ...state,
        portinIccidData: payload,
      };

    case GET_LISTPORTATION_PORTIN: {
      return {
        ...state,
        portationListData: payload,
      };
    }

    case PORTIN_COMMONE: {
      return {
        ...state,
        portInCommoneDetails: payload,
      };
    }

    case GET_CUSTOMER_INFO: {
      return {
        ...state,
        customerInfo: payload,
      };
    }

    case PORTIN_PRIVATE: {
      return {
        ...state,
        portInPrivateData: payload,
      };
    }

    case PORTIN_PRIVATE_ASAP: {
      return {
        ...state,
        portInPrivateAsapData: payload,
      };
    }

    case PORTIN_PRIVATE_VTEN: {
      return {
        ...state,
        portInPrivateVtenData: payload,
      };
    }

    case PORTIN_COMPANY: {
      return {
        ...state,
        portInCompanyData: payload,
      };
    }

    case PORTIN_COMPANY_ASAP: {
      return {
        ...state,
        portInCompanyAsapData: payload,
      };
    }

    case PORTIN_COMPANY_VTEN: {
      return {
        ...state,
        portInCompanyVtenData: payload,
      };
    }

    case GET_PROVIDERS_LIST: {
      return {
        ...state,
        mnpProvidersList: payload,
      };
    }

    default:
      return state;
  }
};
export default reducer;
