import { GET_BALANCE, SUBSCRIPTION_INFO } from "redux/constants";

const initialState = {
  getBalanceData: {},
  subscriptionInfo: {},
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BALANCE: {
      return {
        ...state,
        getBalanceData: payload,
      };
    }
    case SUBSCRIPTION_INFO:
      return {
        ...state,
        subscriptionInfo: payload,
      };

    default:
      return state;
  }
};
export default reducer;
